import {useMemo, useState} from "react";
import {PlatformPage} from "@tracabois/template";
import {useTranslation} from "react-i18next";
import {Action, useActionFeedback} from "@komune-io/g2";
import {DisableUserPopUp} from "../../components/DisableUserPopUp";
import {UserForm} from "../../components/UserForm/UserForm";
import {User, useUserFormState} from "@komune-io/g2-im";
import {useParams} from "react-router";

export interface UserUpdatePageProps {
  update?: boolean;
  readonly?: boolean;
  title?: string;
  userId?: string
}

export const UserEditPage = (props: UserUpdatePageProps) => {
  const {title} = props;
  const {t} = useTranslation()
  const feedback = useActionFeedback()
  const [openDisablePopup, setOpenDisablePopup] = useState(false)

  const {userId = props.userId} = useParams<{ userId?: string }>();

  const { formState, isLoading, user } = useUserFormState<User>({
    userId: userId,
    update: true,
    multipleRoles: false
  })


  const handleSave = async (): Promise<boolean> => {
    await formState.submitForm()
    feedback.setFeedback(true)
    return true
  }

  const handleDelete = () => setOpenDisablePopup(true)

  const actions: Action[] = useMemo(() => ([{
    key: "save",
    label: t("save"),
    onClick: handleSave,
    ...feedback
  }, {
    key: "disable",
    label: t("user.disable"),
    onClick: handleDelete,
    variant: 'deletion'
  }]), [feedback])
  return (
    <PlatformPage title={title ?? t("user.one")} actions={actions}>
      <UserForm formState={formState} isLoading={isLoading} user={user} update/>
      <DisableUserPopUp userId={userId} open={openDisablePopup} onClose={() => setOpenDisablePopup(false)} />
    </PlatformPage>
  );
};
