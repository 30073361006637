import {city as evidence} from "tracabois-platform-product-evidence-domain";

export type ProductEvidenceDownloadQuery = evidence.smartb.tracabois.platform.f2.product.evidence.domain.query.ProductEvidenceDownloadQueryDTO
import {useAuthenticatedRequest} from "../../../config/useAuthenticatedRequest";
import {useFetchBinary} from "@komune-io/g2";

export type UseProductEvidenceDownloadQuery = (a: ProductEvidenceDownloadQuery) =>  Promise<string | undefined>;

export const useProductEvidenceDownloadQuery = (): UseProductEvidenceDownloadQuery  => {
  const requestProps = useAuthenticatedRequest()
  return useFetchBinary<ProductEvidenceDownloadQuery>("productEvidenceDownload", requestProps)
}