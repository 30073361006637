import {OrganizationFactory, Organization, OrganizationFactoryFieldsOverride} from "@komune-io/g2-im";
import {useParams} from "react-router";
import {PlatformSubSection} from "@tracabois/template";
import {Grid} from "@mui/material";
import {
  SubOrganizationSection,
  BrandNumberSection,
  DropZonePictureAction
} from "@tracabois/domain";
import {useTranslation} from "react-i18next";
import {FormComposableState} from "@komune-io/g2";
import {OrganizationUserList} from "../../../user/components/OrganizationUserList";
import {BtmcOrganization} from "@tracabois/domain/src/s2/brandOrganization/model";
import {orgRolesOptions, useExtendedAuth} from "../../../auth";
import {useMemo} from "react";


interface BtmcForm {
  organization?: BtmcOrganization
  formState: FormComposableState
  imageDropZone: DropZonePictureAction
}

export interface OrgFactoryPros {
  readonly?: boolean
  update?: boolean
  organizationId?: string
  formState: FormComposableState,
  organization?: Organization,
  isLoading?: boolean,
  btmcForm?: BtmcForm
  refreshData?: () => void
}

export const OrganizationForm =(props: OrgFactoryPros) => {
  const { update = false, formState, btmcForm, organization, isLoading, refreshData = () => {} } = props;
  const { organizationId = props.organizationId!!} = useParams<{ organizationId: string }>();
  const {t} = useTranslation()
  const {service} = useExtendedAuth()
  const fieldsOverride = useMemo((): OrganizationFactoryFieldsOverride => {
    return {
      logo: {
        customDisplay: () => <></>
      },
      roles: {
        validator: (value: string[]) => {
          if(value.length == 0) {
            return t("organization.validator.roles.mandatory")
          }
          return undefined
        },
        params: {
          // TODO CHeck with Basile why orror occors on creation form when multiple is set to true
          multiple: update,
          readonlyType: 'chip',
          options: orgRolesOptions,
          disabled: !service.isMarque(),
        },
      }
    }
  }, [t])
  return (
    <>
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <PlatformSubSection title={t("informations")}>
          <OrganizationFactory
            formState={formState}
            organization={organization}
            isLoading={isLoading}
            fieldsOverride={fieldsOverride}
          />
        </PlatformSubSection>
      </Grid>
      {btmcForm &&(
        <Grid item xs={6}>
          <BrandNumberSection
            dropZonePictureAction={btmcForm.imageDropZone}
            item={btmcForm.organization}
            form={btmcForm.formState}
          />
          <SubOrganizationSection
                refreshData={refreshData}
                organizationId={organizationId}
                subOrganizations={btmcForm?.organization?.subOrganizations || []}
            />
        </Grid>
      )}
    </Grid>
    {update && (
      <PlatformSubSection title={t("member")}>
        <OrganizationUserList
          myOrganization={false}
          organizationId={organizationId}
        />
      </PlatformSubSection>
    )}
  </>
  );
};
