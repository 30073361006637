import {Column, Row} from '@komune-io/g2-layout'
import React, {useMemo} from 'react'
import {PlatformTable} from "@tracabois/template";
import {OffsetPagination, PageResult} from "@tracabois/utils";
import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";

interface PlatformPaginatedTableProps<DATA extends object> {
  page?: PageResult<DATA>,
  isLoading?: boolean
  onPageChange: (page: OffsetPagination) => void;
  onRowClicked?: (row: Row<DATA>) => void;
  /**
   * The definition of columns
   */
  columnsDefinition?: (t: TFunction) => Column<DATA>[];
  columns?: Column<DATA>[];
  /**
   * Component to filter table
   */
  actions?: React.ReactNode;
  /**
   * No box elevation if true
   */
  noElevation?: boolean
}


const emptyPageResult = <DATA extends {}>(): PageResult<DATA> => ({
  items: [],
  pagination: {offset: 0, limit: 0},
  total: 0,
})

export const PlatformPaginatedTable = <DATA extends {}>(props: PlatformPaginatedTableProps<DATA>) => {
  const {page = emptyPageResult(), columnsDefinition, columns = [], isLoading, onPageChange, onRowClicked, actions, noElevation} = props
  const {t} = useTranslation()
  const currentPage: number = useMemo(() => {
    return (page.pagination.offset / page.pagination.limit) + 1
  }, [page.pagination])

  const totalPage: number = useMemo(() => {
    return Math.floor((page.total - 1) / page.pagination.limit) + 1
  }, [page.total, page.pagination])
  const columnsFinal: Column<DATA>[] = useMemo(
    () => columnsDefinition ? columnsDefinition(t) : columns, []
  )

  const handlePageChange = (pageNumber: number) => {
    const limit = page.pagination.limit
    const offset = (pageNumber - 1) * limit
    onPageChange({offset: offset, limit: limit})
  }
  return (
    <PlatformTable<DATA>
      data={page.items}
      columns={columnsFinal}
      isLoading={isLoading}
      page={currentPage}
      filter={actions}
      totalPages={totalPage}
      handlePageChange={handlePageChange}
      variant='grounded'
      onRowClicked={onRowClicked}
      noElevation={noElevation}
    />
  )
}
