import { MenuItem } from "@komune-io/g2-components";
import {G2Row} from "@komune-io/g2-layout";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LinkProps } from "react-router-dom";
import { EditRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {User, useUserColumns} from "@komune-io/g2";
import {userRoute} from "../../route";
import {OrganizationId} from "@komune-io/g2-im/dist/Organization";

export interface UseUserListPageProps {
  withOrganizationColumn?: boolean
}

export const useUserListPage = (props?: UseUserListPageProps) => {
  const { withOrganizationColumn = false } = props || {}
  const { t } = useTranslation();
  const navigate = useNavigate()

  const getActions = useCallback(
    (user: User): MenuItem<LinkProps>[] => {
      return [
       {
          key: "edit",
          label: t("edit"),
          icon: <EditRounded />,
          component: Link,
          componentProps: {
            to: `/users/${user.id}/edit`
          }
        }
      ]
    },
    [],
  )

  const onRowClicked = useCallback(
    (row: G2Row<User>) => {
      userRoute.goto.editUser(row.original.id, navigate)
    },
    [navigate],
  )

  const getOrganizationUrl = useCallback(
    (organizationId: OrganizationId) => `/organizations/${organizationId}/view`,
    [],
  )
  const columns = useUserColumns({
    getOrganizationUrl:getOrganizationUrl,
    hasOrganizations: withOrganizationColumn,
    getActions: getActions
  }).columnsArray

  return {
    getActions,
    onRowClicked,
    getOrganizationUrl,
    columns,
  }
}