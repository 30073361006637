import { useCallback, useMemo } from "react";
import {
  UserFactory,
  useGetOrganizationRefs,
  User,
  UserFactoryFieldsOverride,
  userExistsByEmail
} from "@komune-io/g2-im";
import { parse } from "qs";
import { PlatformSubSection } from "@tracabois/template";
import { Grid } from "@mui/material";
import { FormComposableState, imConfig } from "@komune-io/g2";
import { rolesOptions, useExtendedAuth } from "../../../auth";
import { useTranslation } from "react-i18next";
import { requiredString } from "@tracabois/utils";

export interface UserFormPros {
  isLoading?: boolean;
  update?: boolean;
  readonly?: boolean;
  formState: FormComposableState
  user?: User
}

export const UserForm = (props: UserFormPros) => {
  const { isLoading = false, readonly = false, formState, user } = props;
  const { keycloak } = useExtendedAuth()
  const { t } = useTranslation()

  const organizationId = useMemo(() => {
    const params = parse(window.location.search, { ignoreQueryPrefix: true });
    if (typeof params.organizationId === "string") {
      return params.organizationId;
    }
    return undefined;
  }, []);

  const getOrganizationRefs = useGetOrganizationRefs({
    jwt: keycloak.token
  })
  const organizationOptions = useMemo(
    () => getOrganizationRefs.query.data?.items.map((ref) => ({ key: ref.id, label: ref.name })),
    [getOrganizationRefs]
  )

  const fieldsOverride = useMemo((): UserFactoryFieldsOverride => {
    return {
      roles: {
        validator: (value: string) => {
          return requiredString(t("user.validator.roles.mandatory"), value)
        },
        params: {
          options: rolesOptions(t)
        }
      },
      street: {
        validator: () => undefined
      },
      postalCode: {
        validator: () => undefined
      },
      city: {
        validator: () => undefined
      },
      memberOf: {
        validator: (value?: string) =>
          requiredString(t("user.validator.memberOf.mandatory"), value),
        params: {
          options: organizationOptions
        }
      }
    }
  }, [organizationOptions])



  const getOrganizationUrl = useCallback(
    (organizationId: string) => `/organizations/${organizationId}/edit`,
    [],
  )

  const checkEmailValidity = useCallback(
    async (email: string) => {
      return userExistsByEmail(email, imConfig().url, keycloak.token)
    },
    [keycloak.token]
  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <PlatformSubSection title="Informations">
          <UserFactory
            readOnly={readonly}
            formState={formState}
            organizationId={organizationId}
            getOrganizationUrl={getOrganizationUrl}
            isLoading={isLoading}
            user={user}
            userId={user?.id}
            multipleRoles={false}
            resetPasswordType={"forced"}
            fieldsOverride={fieldsOverride}
            checkEmailValidity={checkEmailValidity}
          />
        </PlatformSubSection>
      </Grid>
      {/*<Grid item xs={4}>*/}
      {/*<PlatformSubSection title="Password">*/}
      {/*{userId && <UserResetPasswordFormAutomated userId={userId}/>}*/}
      {/*</PlatformSubSection>*/}
      {/*</Grid>*/}
    </Grid>
  );
};
