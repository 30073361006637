import {city} from "tracabois-platform-ouvrage-domain";
import {city as query} from "tracabois-platform-ouvrage-query-domain";
import {city as stats} from "tracabois-platform-stats-domain";
import {S2StateProp} from "@tracabois/utils";

export abstract class OuvrageState implements city.smartb.tracabois.platform.s2.ouvrage.domain.automate.OuvrageState {
  abstract get name(): "Exists" | "Deleted" | "Finished"
  abstract get ordinal(): 0 | 1 | 2
  abstract get position(): number
}

export type OuvrageCreateCommand = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageCreateCommandDTO
export type OuvrageCreatedEvent = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageCreatedEventDTO

export type OuvrageUpdateCommand = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageUpdateCommandDTO
export type OuvrageUpdatedEvent = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageUpdatedEventDTO

export type OuvrageDeleteCommand = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageDeleteCommandDTO
export type OuvrageDeletedEvent = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageDeletedEventDTO

export type OuvrageFinishCommand = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageFinishCommandDTO
export type OuvrageFinishedEvent = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageFinishedEventDTO

export type OuvrageUnlockCommand = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageUnlockCommandDTO
export type OuvrageUnlockedEvent = city.smartb.tracabois.platform.s2.ouvrage.domain.command.OuvrageUnlockedEventDTO

export type OuvrageGetQuery = query.smartb.tracabois.platform.f2.ouvrage.query.domain.query.OuvrageGetQueryDTO
export type OuvrageGetResponse = query.smartb.tracabois.platform.f2.ouvrage.query.domain.query.OuvrageGetResponseDTO

export type Ouvrage = query.smartb.tracabois.platform.f2.ouvrage.query.domain.model.OuvrageDTO
export type OuvrageModel = query.smartb.tracabois.platform.s2.ouvrage.domain.model.OuvrageModelDTO
export type OuvrageRef = city.smartb.tracabois.platform.s2.ouvrage.domain.model.OuvrageRefDTO
export type OuvrageIndicator = stats.smartb.tracabois.platform.s2.stats.domain.model.OuvrageIndicatorsDTO

export type OuvrageId = string


export const canFinishOuvrage = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}

export const isEditableOuvrage = (realization?: Ouvrage): boolean => {
  // @ts-ignore
  return realization?.status == "Exists"
}

export const isOuvrageFinished = (realization?: Ouvrage): boolean => {
  // @ts-ignore
  return realization?.status == "Finished"
}

export const canUnlockOuvrage = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}

export const toOuvrageState = (entity?: Ouvrage | OuvrageModel): S2StateProp => {
  const statue = entity?.status
  // @ts-ignore
  if(statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if(statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}

export const ouvrageToUpdateOuvrageCommand = (ouvrage: Ouvrage): OuvrageUpdateCommand => {
  return  {
    id: ouvrage.id,
    name: ouvrage.name,
    description: ouvrage.description,
  }
}
