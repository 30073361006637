import {GetOrganizationRefsOptions, OrganizationRef, useGetOrganizationRefs as useGetOrganizationRefsI2} from "@komune-io/g2-im";
import {useExtendedAuth} from "../../auth";

export interface OrganizationRefsAllParams {
  queryKey?: string
  options?: GetOrganizationRefsOptions,
  roles?: string[]
}

export interface OrganizationRefsResult {
  isLoading: boolean
  data: OrganizationRef[]
}

export const LastTransformationRolesRoles: string[] = ["Charpentier", "Menuisier"]
export const FirstTransformationRoles: string[] = ["Lamelliste", "Négoce", "Scieur"]
export const TransformationRoles: string[] = ["Charpentier", "Lamelliste", "Menuisier", "Négoce", "Scieur"]
export const MoeRoles: string[] = ["Financeur", "Maitre d'ouvrage", "Maitre d'oeuvre"]

export const isLastTransformationRolesRoles = (ref: OrganizationRef | undefined): boolean => {
  if(ref == undefined) return false
  return ref.roles.filter( it => LastTransformationRolesRoles.includes(it)).length != 0
}

export const useGetOrganizationRefs = (params: OrganizationRefsAllParams): OrganizationRefsResult => {
  const {options, queryKey = 'organizationRefs', roles} = params
  const {keycloak} = useExtendedAuth()
  const result = useGetOrganizationRefsI2({
    jwt: keycloak.token,
    options: options,
    queryKey: queryKey,
  })

  const isLoading = result.query.isLoading
  if (isLoading) {
    return {
      isLoading: isLoading,
      data: []
    }
  } else {
    let refs = Array.from(result.map.values());
    if (roles != undefined) {
      refs = refs.filter((ref: OrganizationRef) => {
        return ref.roles?.map(roleName => {
          return roles!!.includes(roleName)
        }).reduce(
          (previousValue, currentValue) => previousValue || currentValue,
          false
        );
      })
    }
    return {
      isLoading: isLoading,
      data: refs
    }
  }
}

