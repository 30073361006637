import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformPaginatedTable, TableCellText, PlatformActions} from "@tracabois/template";
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {OrganizationRef} from "../../../../referentiels/organizationType";
import {OrgRolesArray} from "@tracabois/connect";

interface RealizationMoeTableProps {
  page: PageResult<OrganizationRef>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<OrganizationRef>) => void;
  actions?: React.ReactNode
}

export const RealizationMoeTable = (props: RealizationMoeTableProps) => {
  const {page, isLoading, onPageQueryChange, actions, onRowClicked} = props
  const filter = (
    actions && <PlatformActions actions={actions}></PlatformActions>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination
    })
  }
  return (<>
      <PlatformPaginatedTable<OrganizationRef>
        page={page}
        isLoading={isLoading}
        actions={filter}
        columnsDefinition={getColumns}
        onPageChange={onPaginationChange}
        onRowClicked={onRowClicked}
      />
    </>
  )
}

const getColumns = (t: TFunction): Column<OrganizationRef>[] => ([{
  Header: `${t("organization.name", "Nom")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<OrganizationRef>) => (
    <TableCellText main={row.original.name} />
  )
}, {
  Header: `${t("organization.type", "Type")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<OrganizationRef>) => {
    return (
      <TableCellText main={row.original.roles?.filter(it => OrgRolesArray.includes(it)).join(', ')}/>
    )
  }
}, {
  Header: `${t("organization.localisation", "Localisation")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<OrganizationRef>) => (
    <TableCellText main={row.original.location|| ""} />
  )
}])

